import '@/_modules/search/search.handler';

export function setSearchOld(searchOld) {
  window.searchOld = searchOld;
}

export function setFocused(focused) {
  window.focused = focused;
}

export function setDataLayer(dataLayer) {
  window.dataLayer = dataLayer;
}

export function setSearchLoaded(searchLoaded) {
  window.searchLoaded = searchLoaded;
}

export function setHandleDomReadyForSearch(handleDomReadyForSearch) {
  window.handleDomReadyForSearch = handleDomReadyForSearch;
}
