import {
  setSearchLoaded,
  setHandleDomReadyForSearch,
  setSearchOld,
  setFocused,
  setDataLayer,
} from '@/_modules/search/search.global';

const { $ } = window;

// account에 tmpl import가 되어있지 않아서 임시처리
if (!$.fn.tmpl) {
  $.fn.tmpl = function () {};
}

$.fn.onlyText = function () {
  return this.contents()
    .filter(function () {
      return this.nodeType !== 1;
    })
    .text()
    .trim();
};

$.fn.onlyTextNode = function () {
  return this.contents().filter(function () {
    return this.nodeType !== 1;
  });
};

// 키워드 기능
const searchkwd = {
  selector: {
    layer: '#layer_search',
    form: '#layer_search form',
    control: '#layer_search form .form_control',
    savedKwdList: '#saved_list',
    autocomplete: '#autocomplete_list',
    recommendList: '#recommend_list',
    savedKwdTmpl: '#saved_kwd_tmpl',
    autocompleteItemTmpl: '#autocomplete_item_tmpl',
    recommendItemTmpl: '#recommend_item_tmpl',
  },
  my: [], // 저장된 키워드 (로그인 데이터 + 로컬스토리지 데이터)
  myRecommend: [], // 추천 검색어와 중복된 저장 키워드 임시 저장
  savemode: (function () {
    // 저장모드 여부
    const stored = window.utils.cookie.get(window.COOKIE_NAME.IS_SAVING_KEYWORD);
    return stored ? stored === 'true' : true;
  })(),
  /**
   * 저장한 키워드 load
   * @param {object} params
   */
  load(params) {
    params = params || {};

    const _this = this;

    this.my = JSON.parse(localStorage.getItem(window.LOCAL_NAME.kwd)) || [];
    this.my = this.my.sort(function (a, b) {
      return b.REG_DT.toDate() - a.REG_DT.toDate();
    });

    // 중복 키워드 제거: 기존 로컬스토리지 저장되어 있던 정보에서 서버 데이터는 제거한다. [JST24-1080]
    this.my = this.my.filter(data => !data.SEQ_NO);
    localStorage.setItem(window.LOCAL_NAME.kwd, JSON.stringify(this.my));

    if (window.utils.member.isMem()) {
      // 중앙 멤버인 경우에만 DB 키워드 조회
      window.utils.ajax.get({
        url: window.utils.config.apiPath + 'keyword.my',
        withCredentials: true,
        success(result) {
          const myData =
            result._DATA.reduce(function (all, cu) {
              if (cu.SCH_KWD) {
                all.push(cu);
              }
              return all;
            }, []) || [];

          let renderKeywords = _this.my.concat(myData);

          // 중복 키워드는 최신 데이터만 남겨둔다.
          renderKeywords = Object.values(
            renderKeywords.reduce(function (acc, current) {
              const keyword = current.SCH_KWD;
              const date = current.REG_DT;
              if (!acc[keyword] || acc[keyword].REG_DT?.toDate() < date?.toDate()) {
                acc[keyword] = current;
              }
              return acc;
            }, {})
          );

          renderKeywords = renderKeywords.sort(function (a, b) {
            return b.REG_DT.toDate() - a.REG_DT.toDate();
          });

          if (renderKeywords.length > 10) {
            renderKeywords = renderKeywords.slice(0, 10);
          }

          _this.my = renderKeywords;

          if (typeof params.callback === 'function') {
            params.callback.call(this);
          }
        },
      });
    } else if (typeof params.callback === 'function') {
      params.callback.call(this);
    }
  },
  /**
   * 저장한 키워드 render
   */
  renderKwd() {
    if ($(this.selector.savedKwdList).length < 1) {
      return;
    }

    const formControl = document.querySelector(this.selector.control);
    const isTypingKeyword = formControl?.childNodes[0]?.nodeType === 3; // 노드 타입이 텍스트
    // if (typeof saved_kwd_tmpl_cloc === 'undefined' || Object.keys(saved_kwd_tmpl_cloc).length < 3 || typeof saved_kwd_tmpl_cloc2 === 'undefined' || Object.keys(saved_kwd_tmpl_cloc2).length < 3) return;

    $(this.selector.savedKwdList).children().remove();
    if (this.my.length > 0) {
      $(this.selector.layer).find('.search_off').hideEle();
      $(this.selector.savedKwdTmpl)
        .tmpl(
          this.my.map(function (k) {
            return {
              LINK_URL: window.SEARCH_PATH + encodeURIComponent(k.SCH_KWD),
              SCH_KWD: k.SCH_KWD,
              DATE: k.REG_DT.toDate().format('MM.dd'),
              SECTION_NAME: window.sectionMenus ? window.sectionMenus.Display : '',
              A_CLOC_CTG:
                typeof window.saved_kwd_tmpl_cloc !== 'undefined' &&
                typeof window.saved_kwd_tmpl_cloc.ctg !== 'undefined'
                  ? window.saved_kwd_tmpl_cloc.ctg
                  : '',
              A_CLOC_ACT:
                typeof window.saved_kwd_tmpl_cloc !== 'undefined' &&
                typeof window.saved_kwd_tmpl_cloc.act.move !== 'undefined'
                  ? window.saved_kwd_tmpl_cloc.act.move
                  : '',
              A_CLOC_LBL:
                typeof window.saved_kwd_tmpl_cloc !== 'undefined' &&
                typeof window.saved_kwd_tmpl_cloc.lbl !== 'undefined'
                  ? window.saved_kwd_tmpl_cloc.lbl
                  : '',
              BTN_CLOC_CTG:
                typeof window.saved_kwd_tmpl_cloc2 !== 'undefined' &&
                typeof window.saved_kwd_tmpl_cloc2.ctg !== 'undefined'
                  ? window.saved_kwd_tmpl_cloc2.ctg
                  : '',
              BTN_CLOC_ACT:
                typeof window.saved_kwd_tmpl_cloc2 !== 'undefined' &&
                typeof window.saved_kwd_tmpl_cloc2.act.click !== 'undefined'
                  ? window.saved_kwd_tmpl_cloc2.act.click
                  : '',
              BTN_CLOC_LBL:
                typeof window.saved_kwd_tmpl_cloc2 !== 'undefined' &&
                typeof window.saved_kwd_tmpl_cloc2.lbl !== 'undefined'
                  ? window.saved_kwd_tmpl_cloc2.lbl
                  : '',
            };
          })
        )
        .appendTo(this.selector.savedKwdList);
      isTypingKeyword ? $(this.selector.savedKwdList).hideEle() : $(this.selector.savedKwdList).showEle();
    } else {
      isTypingKeyword
        ? $(this.selector.layer).find('.search_off').hideEle()
        : $(this.selector.layer).find('.search_off').showEle();
      $(this.selector.layer)
        .find('.search_off')
        .text(this.savemode ? '저장된 검색어가 없습니다.' : '검색어 저장 기능이 꺼져있습니다.');
      $(this.selector.savedKwdList).hideEle();
    }
  },
  /**
   * 키워드 저장 (동일한 키워드가 있으면 날짜만 업데이트)
   * @param {string} kwdText 저장하는 키워드
   */
  saveKwd(kwdText) {
    if (this.savemode) {
      // 검색어 저장 O일 때 로컬에도 저장
      const regDt = new Date().format('yyyy-MM-dd HH:mm:ss');
      const exists = this.my.indexOfObject('SCH_KWD', kwdText);

      if (exists > -1) {
        this.my.splice(exists, 1, $.extend({}, this.my[exists], { REG_DT: regDt }));
      } else {
        this.my.push({ SCH_KWD: kwdText, REG_DT: regDt });
        // 최대 열개
        if (this.my.length > 10) {
          this.my.shift();
        }
      }
      if (!window.utils.member.isMem()) {
        localStorage.setItem(window.LOCAL_NAME.kwd, JSON.stringify(this.my));
      }
      this.renderKwd();
    }

    this.insertKwd(kwdText);
  },
  /**
   * keyword.insert post
   * @param {string} kwdText 저장하는 키워드
   * @param {func} callback callback
   */
  insertKwd(kwdText, callback) {
    let { dev } = window.utils.device;
    dev = dev === 'B' ? 'T' : dev === 'A' ? 'M' : dev;

    let referer = location.origin + location.pathname;
    // DB가 최대 100자
    if (referer.length > 100) {
      referer = referer.slice(0, 100);
    }

    if (location.pathname === '/' || location.pathname === '/search' || location.pathname === '/search/unifiedsearch') {
      // 회원유무 관계없이 항상 insert 하는 경우
      const tab = location.pathname === '/' ? 'MAIN' : 'TOT';

      window.utils.ajax.post({
        url: window.utils.config.apiPath + 'keyword.insert',
        data: {
          kwd: kwdText,
          referer,
          dev,
          tab,
          my: window.utils.member.isMem() && this.savemode ? 'Y' : 'N',
        },
        withCredentials: true,
        success(result) {
          callback && callback.call(this, result);
        },
      });
    } else if (window.utils.member.isMem() && this.savemode) {
      // 그 외의 경우에는 일단 회원이고 키워드 저장하겠다고 한 경우에만 tab null로 insert
      window.utils.ajax.post({
        url: window.utils.config.apiPath + 'keyword.insert',
        data: {
          kwd: kwdText,
          referer,
          dev,
          tab: null,
          my: 'Y',
        },
        withCredentials: true,
        success(result) {
          callback && callback.call(this, result);
        },
      });
    }
  },
  /**
   * 키워드 삭제
   * @param {string} kwdText 삭제하는 키워드
   */
  deleteKwd(kwdText) {
    const idx = this.my.indexOfObject('SCH_KWD', kwdText);
    if (idx > -1) {
      this.my.splice(idx, 1);
      this.renderKwd();
    }
    if (window.utils.member.isMem()) {
      // DB에 검색어 삭제
      window.utils.ajax.post({
        url: window.utils.config.apiPath + 'keyword.delete',
        data: { kwd: kwdText },
        withCredentials: true,
        success() {},
      });
    }

    // 키워드를 삭제한 리스트로 로컬 스토리지 데이터 갱신 (2024-08-09 추가, JST24-1080)
    const localKeywords = JSON.parse(localStorage.getItem(window.LOCAL_NAME.kwd));
    const result = localKeywords.filter(info => info.SCH_KWD !== kwdText);
    localStorage.setItem(window.LOCAL_NAME.kwd, JSON.stringify(result));
  },
  /**
   * 검색어 히스토리 삭제 = 키워드 전체 삭제
   */
  removeHistory() {
    $(this.selector.savedKwdList).children().remove();
    this.my = [];
    localStorage.removeItem(window.LOCAL_NAME.kwd);
    this.renderKwd();

    if (window.utils.member.isMem()) {
      // DB에 검색어 삭제
      window.utils.ajax.post({
        url: window.utils.config.apiPath + 'keyword.delete',
        data: { scope: 'Y' },
        withCredentials: true,
        success() {},
      });
    }

    this.toggleMyKeywordsInAutoComplete(false, true);
  },
  /**
   * 저장모드 변경
   * @param {boolean} newmode true | false
   */
  changeSaveMode(newmode) {
    const _this = this;
    this.savemode = newmode;
    window.utils.cookie.set(window.COOKIE_NAME.IS_SAVING_KEYWORD, newmode, Infinity);

    if (newmode) {
      $(this.selector.layer).find('.saving').text('검색어 저장 끄기');
      $(this.selector.layer).find('.saving').attr('data-evnt-lbl', '검색어 저장 끄기');
      $(this.selector.savedKwdList).showEle();
      $(this.selector.layer).find('.removeHistory').showEle();
      _this.load({
        callback() {
          _this.renderKwd();
        },
      });
    } else {
      $(this.selector.layer).find('.saving').text('검색어 저장 켜기');
      $(this.selector.layer).find('.saving').attr('data-evnt-lbl', '검색어 저장 켜기');
      $(this.selector.layer).find('.search_off').showEle();
      $(this.selector.savedKwdList).hideEle();
      $(this.selector.layer).find('.removeHistory').hideEle();
      this.my = [];
      this.renderKwd();
    }
    this.toggleMyKeywordsInAutoComplete(newmode);
  },
  /**
   * 자동완성 조회
   * @param {string} query query
   * @param {string} selector 자동완성 li 넣을 선택자
   */
  getAutoComplete(query, selector) {
    const _this = this;
    if (!selector || selector === '') return;

    clearTimeout(_this.timer);
    query = this.filterKwd(query);

    if (query.length > 0) {
      _this.timer = setTimeout(function () {
        window.utils.ajax.get({
          url: window.utils.config.apiPath + 'search.autocomplete',
          data: { query },
          success(result) {
            const $control = $(selector)
              .parents('.search_input_list')
              .siblings('form.search_form')
              .find('.form_control');

            if (!$control.onlyText()) {
              return;
            }

            $(selector).html('');

            const concatList =
              (result._DATA || []).indexOfObject('KEYWORD', query) > -1
                ? result._DATA
                : [].concat({ KEYWORD: query }, result._DATA);

            // 내 히스토리도 추가, 키워드 저장 모드 전환시 해당 키워드 제거를 위해 myRecommend에 임시 저장
            _this.myRecommend = _this.my
              .filter(k => k.SCH_KWD.startsWith(query) && concatList.indexOfObject('KEYWORD', k.SCH_KWD) < 0)
              .map(k => ({ KEYWORD: k.SCH_KWD, DATA_INDEX: -1 }));

            concatList.forEach((k, index) => {
              const isMy = _this.my.indexOfObject('SCH_KWD', k.KEYWORD) > -1;
              const isSaved = _this.myRecommend.indexOfObject('KEYWORD', k.KEYWORD) > -1;
              if (isMy && !isSaved) {
                // 내 키워드와 중복된 키워드. 저장모드 OFF시 재정렬 할 인덱스를 함께 저장한다.
                _this.myRecommend.push({ KEYWORD: k.KEYWORD, DATA_INDEX: index });
              }
            });

            concatList.push(..._this.myRecommend.filter(item => item.DATA_INDEX < 0));

            const lis = $(_this.selector.autocompleteItemTmpl).tmpl(
              concatList
                .map(function (k, idx) {
                  // if (typeof autocomplete_item_tmpl_cloc === 'undefined' || Object.keys(autocomplete_item_tmpl_cloc).length < 3) return;
                  const myIdx = _this.my.indexOfObject('SCH_KWD', k.KEYWORD);
                  return {
                    INDEX: idx,
                    REG_DT: myIdx > -1 ? _this.my[myIdx].REG_DT : null,
                    DATE: myIdx > -1 ? _this.my[myIdx].REG_DT.toDate().format('MM.dd') : null,
                    LINK_URL: window.SEARCH_PATH + encodeURIComponent(k.KEYWORD),
                    SCH_KWD: k.KEYWORD,
                    SECTION_NAME: window.sectionMenus ? window.sectionMenus.Display : '',
                    A_CLOC_CTG:
                      typeof window.autocomplete_item_tmpl_cloc !== 'undefined' &&
                      typeof window.autocomplete_item_tmpl_cloc.ctg !== 'undefined'
                        ? window.autocomplete_item_tmpl_cloc.ctg
                        : '',
                    A_CLOC_ACT:
                      typeof window.autocomplete_item_tmpl_cloc !== 'undefined' &&
                      typeof window.autocomplete_item_tmpl_cloc.act.move !== 'undefined'
                        ? window.autocomplete_item_tmpl_cloc.act.move
                        : '',
                    A_CLOC_LBL:
                      typeof window.autocomplete_item_tmpl_cloc !== 'undefined' &&
                      typeof window.autocomplete_item_tmpl_cloc.lbl !== 'undefined'
                        ? window.autocomplete_item_tmpl_cloc.lbl
                        : '',
                    BTN_CLOC_CTG:
                      typeof window.autocomplete_item_tmpl_cloc !== 'undefined' &&
                      typeof window.autocomplete_item_tmpl_cloc.ctg !== 'undefined'
                        ? window.autocomplete_item_tmpl_cloc.ctg
                        : '',
                    BTN_CLOC_ACT:
                      typeof window.autocomplete_item_tmpl_cloc !== 'undefined' &&
                      typeof window.autocomplete_item_tmpl_cloc.act.move !== 'undefined'
                        ? window.autocomplete_item_tmpl_cloc.act.move
                        : '',
                    BTN_CLOC_LBL:
                      typeof window.autocomplete_item_tmpl_cloc !== 'undefined' &&
                      typeof window.autocomplete_item_tmpl_cloc.lbl !== 'undefined'
                        ? window.autocomplete_item_tmpl_cloc.lbl
                        : '',
                  };
                })
                // sort 기준 1. 이전에 검색했던 적 있는 검색어, 2. 입력한 키워드랑 완전 동일한 검색어, 3. 그 외 자동완성 순서
                .sort(function (a, b) {
                  const savedA = _this.my.indexOfObject('SCH_KWD', a.SCH_KWD) > -1;
                  const savedB = _this.my.indexOfObject('SCH_KWD', b.SCH_KWD) > -1;
                  const sameA = query === a.SCH_KWD;
                  const sameB = query === b.SCH_KWD;

                  if (savedA && savedB) {
                    return sameA
                      ? 1
                      : sameB
                      ? -1
                      : a.REG_DT && b.REG_DT
                      ? b.REG_DT.toDate() - a.REG_DT.toDate()
                      : a.INDEX - b.INDEX;
                  } else if (savedA && !savedB) {
                    return -1;
                  } else if (!savedA && savedB) {
                    return 1;
                  }

                  return sameA ? -1 : sameB ? 1 : a.INDEX - b.INDEX;
                })
                // 최대 10개
                .slice(0, 10)
            );
            lis.each(function () {
              const text = $(this).find('a').text();
              if (text === query) $(this).find('.info').remove();
              $(this)
                .find('a')
                .html(text.replace(query, '<strong>' + query + '</strong>'));
              $(this).find('a').attr('tabindex', 0); // safari 검색 결과 페이지에서 자동완성 단어 클릭 시 아무런 동작이 없는 문제를 수정하기 위해 추가
            });

            // 자동완성 클릭시 검색어 선택한 자동완성 텍스트로 변경
            lis.find('.btn_link').click(function (e) {
              e.preventDefault();
              const text = $($(this).parents()[1]).find('a').text();

              $control.text(text);
              _this.getAutoComplete(text, selector);
              if (selector === searchkwd.selector.autocomplete) {
                _this.getRecommend(text);
              }

              const range = document.createRange();
              const sel = window.getSelection();
              range.setStart($control[0].childNodes[0], text.length);
              range.collapse(true);
              sel.removeAllRanges();
              sel.addRange(range);
              $control.focus();
            });

            lis.appendTo(selector);
            concatList.length > 0 ? $(selector).showEle() : $(selector).hideEle();
          },
        });
        _this.timer = -1;
      }, 150);
    } else {
      $(selector).html('');
      $(selector).hideEle();
      this.myRecommend = [];
    }
  },
  /**
   * 검색 입력창 개선 - 자동완성에서 내 검색어 노출상태 변경[JST24-1080]
   * @param {boolean} newStatus 노출상태 on/off
   * @param {boolean} remove 내 키워드 삭제 여부
   */
  toggleMyKeywordsInAutoComplete(newStatus, remove) {
    if (!newStatus) {
      if (this.myRecommend.length < 1) return;
      const $list = document.querySelector(this.selector.autocomplete);
      const removalItems = [];
      const reorderItems = [];
      for (const $item of $list.children) {
        const $el = $item.getElementsByTagName('a');
        const keyword = $el.length > 0 ? $el[0].innerText : '';
        const myIndex = this.myRecommend.indexOfObject('KEYWORD', keyword);
        if (myIndex > -1) {
          // 제거할 아이템에 추가한다. (loop 내에서 바로 제거하면 인덱싱에 문제가 발생함)
          removalItems.push($item);
          if (this.myRecommend[myIndex].DATA_INDEX > -1) {
            reorderItems.push({ el: $item, index: this.myRecommend[myIndex].DATA_INDEX });
          }
        }
        // 리스트에서 날짜를 제거한다.
        const $date = $item.querySelector('.date');
        $date && $date.remove();
      }
      // 저장 키워드 엘리먼트 제거
      removalItems.forEach($el => {
        $el.remove();
      });
      // 엘리먼트 재배열(내 키워드와 중복된 API 키워드를 재정렬)
      reorderItems.forEach(item => {
        const { el: $el, index } = item;
        if ($el && index > -1) {
          $list.insertBefore($el, $list.children[index]);
        }
      });
      if (remove) {
        this.myRecommend = [];
      }
    } else if (this.my.length) {
      // 저장된 검색어가 있는 경우에만 자동완성 다시 호출
      const formControl = document.querySelector(this.selector.control);
      const query = formControl?.innerText;
      this.getAutoComplete(query, this.selector.autocomplete);
    }
  },
  /**
   * 추천 검색어 조회
   * @param {string} query
   */
  getRecommend(query) {
    const _this = this;
    clearTimeout(_this.rtimer);

    if (query.length > 0) {
      const data = { query };

      // 진입 URL별로 요청 data 변경
      if (location.pathname.startsWith('/issue')) {
        $.extend(data, { docType: 'package', pkgDiv: 'I' });
      } else if (location.pathname.startsWith('/series')) {
        $.extend(data, { docType: 'package', pkgDiv: 'S' });
      } else if (location.pathname.startsWith('/topic')) {
        $.extend(data, { docType: 'package', pkgDiv: 'T' });
      } else if (location.pathname.startsWith('/newsletter')) {
        $.extend(data, { docType: 'newsletter' });
      } else if (location.pathname.startsWith('/jpod')) {
        $.extend(data, { docType: 'podcast' });
      }

      _this.rtimer = setTimeout(function () {
        window.utils.ajax.get({
          url: window.utils.config.apiPath + 'search.recommend',
          data,
          success(result) {
            if (!$(searchkwd.selector.control).onlyText()) {
              return;
            }

            $(_this.selector.recommendList).html('');

            const lis = $(_this.selector.recommendItemTmpl).tmpl(
              result._DATA.map(function (r) {
                // if (typeof recommend_item_tmpl_cloc === 'undefined' || Object.keys(recommend_item_tmpl_cloc).length < 3) return;
                let linkUrl = '';
                let plusCss = '';
                let plusBadge = '';
                if (r.DOCUMENT_TYPE === 'package') {
                  // 패키지
                  linkUrl =
                    window.utils.config.webPath +
                    (r.PKG_DIV === 'S' ? '/series/' : r.PKG_DIV === 'T' ? '/topic/' : '/issue/') +
                    r.ID;
                } else if (r.DOCUMENT_TYPE === 'podcast') {
                  // 팟캐스트
                  linkUrl = window.utils.config.webPath + '/jpod/channel/' + r.ID;
                } else if (r.DOCUMENT_TYPE === 'newsletter') {
                  // 뉴스레터 (letterSeq가 넘어와서 newsletter_redirect로 이동)
                  linkUrl = window.utils.config.webPath + '/newsletter_redirect/' + r.ID;
                } else if (r.DOCUMENT_TYPE === 'pay_package') {
                  // 시리즈 카드
                  linkUrl = window.utils.config.webPath + '/plus/series/' + r.ID;
                  plusCss = 'nav_link';
                  plusBadge = '<strong class="badge_plus"><span class="visually_hidden">유료 전용</span></strong>';
                } else if (r.DOCUMENT_TYPE === 'direct_link') {
                  linkUrl = r.LINK;
                }
                return {
                  LINK_URL: linkUrl,
                  SCH_KWD: r.KEYWORD,
                  SECTION_NAME: window.sectionMenus ? window.sectionMenus.Display : '',
                  A_CLOC_CTG:
                    typeof window.recommend_item_tmpl_cloc !== 'undefined' &&
                    typeof window.recommend_item_tmpl_cloc.ctg !== 'undefined'
                      ? window.recommend_item_tmpl_cloc.ctg
                      : '',
                  A_CLOC_ACT:
                    typeof window.recommend_item_tmpl_cloc !== 'undefined' &&
                    typeof window.recommend_item_tmpl_cloc.act.move !== 'undefined'
                      ? window.recommend_item_tmpl_cloc.act.move
                      : '',
                  A_CLOC_LBL:
                    typeof window.recommend_item_tmpl_cloc !== 'undefined' &&
                    typeof window.recommend_item_tmpl_cloc.lbl !== 'undefined'
                      ? window.recommend_item_tmpl_cloc.lbl
                      : '',
                  BTN_CLOC_CTG:
                    typeof window.recommend_item_tmpl_cloc !== 'undefined' &&
                    typeof window.recommend_item_tmpl_cloc.ctg !== 'undefined'
                      ? window.recommend_item_tmpl_cloc.ctg
                      : '',
                  BTN_CLOC_ACT:
                    typeof window.recommend_item_tmpl_cloc !== 'undefined' &&
                    typeof window.recommend_item_tmpl_cloc.act.move !== 'undefined'
                      ? window.recommend_item_tmpl_cloc.act.move
                      : '',
                  BTN_CLOC_LBL:
                    typeof window.recommend_item_tmpl_cloc !== 'undefined' &&
                    typeof window.recommend_item_tmpl_cloc.lbl !== 'undefined'
                      ? window.recommend_item_tmpl_cloc.lbl
                      : '',
                  PLUS_CSS: plusCss,
                  PLUS_BADGE: plusBadge,
                };
              })
            );
            lis.each(function () {
              const text = $(this).find('a').text();
              $(this)
                .find('a')
                .html(text.replace(/query/i, '<strong>' + query + '</strong>'));
            });
            lis.appendTo(_this.selector.recommendList);
            result._DATA.length > 0
              ? $(_this.selector.recommendList).showEle()
              : $(_this.selector.recommendList).hideEle();
          },
        });
        _this.rtimer = -1;
      }, 150);
    } else {
      $(_this.selector.recommendList).html('');
      $(_this.selector.recommendList).hideEle();
    }
  },
  /**
   * 검색어 필터링
   * @param {string} kwdText 검색어
   * @param {Element} el input element
   */
  filterKwd(kwdText, el) {
    // entity 제거
    const filtered = kwdText
      .replace('&nbsp;', '')
      .replace('&nbsp', '')
      .replace('&amp;', '&')
      .replaceAll('<', '')
      .replaceAll('>', '')
      .replaceAll('&lt;', '')
      .replaceAll('&gt;', '');

    if (el && el.firstChild && el.firstChild.nodeType === 3) {
      el.firstChild.textContent = filtered;
    }

    return filtered;
  },
};

// 상세검색
const unified = {
  init: {
    searchDate: 'all', // 날짜 선택 radio value
    startDate: null, // 시작일 yyyy-MM-dd
    endDate: null, // 종료일 yyyy-MM-dd
    searchin: '', // 반드시 포함하는 단어
    accurateWord: '', // 정확히 일치하는 단어
    stopword: '', // 제외하는 단어
    sourceCode: null, // 매체(중앙 1,3, 선데이 61)
    sfield: 'all', // 검색 범위(all, title, content, tag)
    serviceCode: null, // 분야(서비스코드 NN)
  },
  query: {},
  form: '#detail_form',
  checkboxs: ['sfield', 'serviceCode', 'sourceCode'],
  /**
   * 쿼리 object (날짜 형식이 다르니 주의) -> query 셋팅 + input 셋팅
   * @param {object} obj 쿼리 object
   */
  set(obj) {
    const _this = this;
    const temp = $.extend({}, obj);

    let diff = 0; // init과 얼마나 다른지 체크하여 하나라도 다르면 버튼 enable 처리
    Object.keys(temp).forEach(function (key) {
      // eslint-disable-next-line eqeqeq
      if (_this.init[key] !== undefined && temp[key] !== '' && _this.init[key] != temp[key]) {
        diff++;
      }
    });
    if (diff > 0) {
      $('#layer_search_option .layer_footer button').each(function () {
        this.removeAttribute('disabled');
      });
      $('.btn_option').addClass('active');
    }

    // 날짜 변환
    if (temp.startDate && temp.endDate) {
      temp.startDate = temp.startDate.toDate().format('yyyy.MM.dd');
      $('[name="startDate"]').val(temp.startDate);
      $('[name="startDate"]').datepicker('option', 'disabled', false);

      temp.endDate = temp.endDate.toDate().format('yyyy.MM.dd');
      $('[name="endDate"]').val(temp.endDate);
      $('[name="endDate"]').datepicker('option', 'disabled', false);
      $('[name="endDate"]').datepicker('option', 'minDate', temp.startDate);

      if (!temp.searchDate || temp.searchDate === '') {
        temp.searchDate = 'direct';
        // $('[name="searchDate"][value="direct"]')[0].checked = true;
      }
      $('[name="searchDate"][value="' + temp.searchDate + '"]').trigger('click');
    }
    // 범위, 카테고리, 매체
    this.checkboxs.forEach(function (cb) {
      if (temp[cb]) {
        let values = [];
        if (cb === 'sourceCode') {
          // 매체는 값 자체가 1,3 처럼 ,로 분리됨
          values.push(temp[cb]);
        } else {
          values = temp[cb].split(',');
        }
        values.forEach(function (sf) {
          $('[name="' + cb + '"][value="all"]')[0].checked = false;
          $('[name="' + cb + '"][value="' + sf + '"]')[0].checked = true;
        });
      }
    });
    if (temp.searchin) {
      $('[name="searchin"]').val(temp.searchin);
    }
    if (temp.accurateWord) {
      $('[name="accurateWord"]').val(temp.accurateWord);
    }
    if (temp.stopword) {
      $('[name="stopword"]').val(temp.stopword);
    }
    this.query = $.extend({}, this.init, temp);
  },
  /**
   * 초기화 (query 초기화 + input 초기화)
   */
  reset() {
    this.query = $.extend({}, this.init);
    $('[name="searchDate"][value="all"]').trigger('click');
    $('[name="startDate"]').val(new Date().format('yyyy.MM.dd'));
    $('[name="startDate"]').datepicker('option', 'disabled', true);
    $('[name="endDate"]').val(new Date().format('yyyy.MM.dd'));
    $('[name="endDate"]').datepicker('option', 'disabled', true);
    this.checkboxs.forEach(function (name) {
      const selector = '[name="' + name + '"]';
      $(selector).each(function () {
        this.checked = this.value === 'all' ? true : false;
      });
    });
    $('[name="searchin"]').val('');
    $('[name="accurateWord"]').val('');
    $('[name="stopword"]').val('');

    $('#layer_search_option #reset_detail').each(function () {
      this.setAttribute('disabled', '');
    });
  },
  /**
   * 날짜 radio onChage
   */
  onChangeDate() {
    // set init
    const isDirect = $('[name="searchDate"]:checked').val() === 'direct';
    $(this.form)
      .find('.hasDatepicker')
      .each(function () {
        $(this).datepicker('option', 'disabled', isDirect ? false : true);
        if (isDirect) {
          // show datepicker
          $(this).parents('.date_wrap').showEle();
        } else {
          // hide datepicker
          $(this).parents('.date_wrap').hideEle();
        }
      });

    // onchage
    $('[name="searchDate"]').change(function () {
      const val = $(this).val();
      $(this.form)
        .find('.hasDatepicker')
        .each(function () {
          $(this).datepicker('option', 'disabled', val === 'direct' ? false : true);
          if (val === 'direct') {
            // show datepicker
            $(this).parents('.date_wrap').showEle();
          } else {
            // hide datepicker
            $(this).parents('.date_wrap').hideEle();
          }
        });
    });

    // 시작일 변경 -> 종료일 최소값도 변경
    $('[name="startDate"]').change(function () {
      $('[name="endDate"]').datepicker('option', 'minDate', $(this).val());
    });
  },
  /**
   * 체크박스 onChange
   */
  onChangeCheckbox() {
    this.checkboxs.forEach(function (name) {
      const selector = '[name="' + name + '"]';
      const inputTot = $(selector).length;

      if (inputTot > 0) {
        $(selector).change(function () {
          const checked = $(selector + ':checked').not('[value="all"]').length;
          const val = $(this).val();

          if (val !== 'all') {
            $(selector + '[value="all"]')[0].checked = false;
          } else {
            // 전체 선택
            $(selector).each(function () {
              this.checked = false;
            });
            $(selector + '[value="all"]')[0].checked = true;
          }
          // eslint-disable-next-line eqeqeq
          if (checked + 1 == inputTot) {
            // 전체 선택
            $(selector).each(function () {
              this.checked = false;
            });
            $(selector + '[value="all"]')[0].checked = true;
          }
        });
      }
    });
  },
};

$(document).ready(function () {
  handleDomReadyForSearch();
});

setHandleDomReadyForSearch(handleDomReadyForSearch);
export function handleDomReadyForSearch() {
  // 변경을 감지할 노드 선택
  const layerSearch = document.getElementById('layer_search');
  if (!layerSearch) {
    return;
  }
  setSearchLoaded(true);

  $(window).resize(function () {
    inputdelete();
    if (location.pathname.indexOf('/search') === 0) {
      inputdelete('.search_header_wrap');
    }
  });
  $('#layer_search .form_control').on('focus', () => {
    const formControl = $('#layer_search .form_control');
    formControl.one('keydown', () => {
      formControl.addClass('focused');
      focusEvent();
    });
  });

  // 감지 옵션 (감지할 변경)
  const mutationOption = { attributes: true, childList: false, subtree: false };

  // 변경 감지 시 실행할 콜백 함수
  const mutationCallback = mutationList => {
    const formControl = $('#layer_search .form_control');
    if (mutationList && mutationList.length > 0) {
      const isClassChange = mutationList.filter(mutation => mutation.attributeName === 'class');
      if (isClassChange.length > 0 && layerSearch.classList.contains('show')) {
        // 사파리에서 레이어가 다 뜨기 전에 검색어 입력 시 '검색어를 입력해주세요' 남아있는 문제를 해결하기 위한 코드
        $('#layer_search .form_control').one('focus', e => {
          const formControlText = $(e.target).onlyText();
          // 사파리에서 레이어가 다 뜨기 전에 검색어 입력 시 input_hint 영역 삭제
          if (formControlText !== '') {
            $('#layer_search .input_hint').hideEle();
            $('#layer_search .input_hint').text('');
          }
        });
        const formControlHtml = formControl.html();
        if (formControlHtml.includes('<br>')) {
          formControl.html(formControlHtml.replace('<br>', ''));
        }
        setTimeout(() => {
          formControl.addClass('focused');
          formControl.blur();
          formControl.focus();
          formControl.on('keydown', () => {
            focusEvent();
          });
        }, 100);
        // [JST24-1014] 무조건 호출 => 검색 페이지 노출시에만 키워드 저장 모드 세팅하도록 변경(불필요한 keyword.my API 호출 제거)
        searchkwd.changeSaveMode(searchkwd.savemode);
      }
    }
  };

  // 콜백 함수에 연결된 감지기 인스턴스 생성
  const observer = new MutationObserver(mutationCallback);

  // 설정한 변경의 감지 시작
  observer.observe(layerSearch, mutationOption);

  // contenteditable에 복붙할 때 태그까지 다 복사되는거 막음
  $('[contenteditable]').on('paste', function (e) {
    e.preventDefault();

    let text = '';
    if (e.clipboardData || e.originalEvent.clipboardData) {
      text = (e.originalEvent || e).clipboardData.getData('text/plain');
    } else if (window.clipboardData) {
      text = window.clipboardData.getData('Text');
    }
    if (document.queryCommandSupported('insertText')) {
      document.execCommand('insertText', false, text);
    } else {
      document.execCommand('paste', false, text);
    }
  });

  /**
   * 공통 검색
   * - 메뉴의 검색 팝업
   */

  // 검색 팝업 레이어 닫기
  $(searchkwd.selector.layer).on('click', '.btn_close', function () {
    // trigger => 키워드 내용 삭제
    $('.layer_search .input_group .btn_delete').trigger('click');

    // 처음 화면 (자동완성 hide 추천 hide 내 검색저장 on)
    $(searchkwd.selector.savedKwdList).showEle();
    $(searchkwd.selector.autocomplete).hideEle();
    $(searchkwd.selector.recommendList).hideEle();
  });

  // 검색창이 열렸을 때 영역내 눌러도 popup 창 계속 열려있도록
  $(searchkwd.selector.layer).click(function (event) {
    const parents = $(event.target).parents();
    if (event.target.className !== 'search_tag_wrap') {
      searcharea(parents, event);
    }
  });

  // 검색어 저장 기능 활성화/비활성화
  $(searchkwd.selector.layer)
    .find('.saving')
    .click(function () {
      searchkwd.changeSaveMode(!searchkwd.savemode);
    });

  // 키워드 개별 삭제
  $(searchkwd.selector.savedKwdList).on('click', '.btn_delete', function () {
    const kwd = $(this).parents('[data-kwd]').data('kwd');
    searchkwd.deleteKwd(kwd);
  });

  // 기록 삭제 = 키워드 전체 삭제
  $(searchkwd.selector.layer).on('click', '.removeHistory', function () {
    searchkwd.removeHistory();
  });

  // form submit
  $(searchkwd.selector.form).submit(function (e) {
    e.preventDefault();
    e.stopPropagation();

    // .input_hint 의 텍스트 때문에 검색으로 넘어가는 경우가 있어서 그 부분 처리
    const formControlElement = $(searchkwd.selector.control)[0].firstChild;
    let val;
    // 파이어폭스에서 firstChild 가 힌트 텍스트로 잡혀서 정상적으로 동작하지 않는 부분 때문에 조건문 추가
    if (formControlElement?.nodeType === 1 && formControlElement?.classList.contains('hide')) {
      val = formControlElement.parentElement.textContent.trim();
    } else {
      val = $(searchkwd.selector.control)[0].firstChild?.wholeText;
      val = $.trim(val);
      val = searchkwd.filterKwd(val, $(searchkwd.selector.control)[0]);
    }

    if (val && val.length > 0) {
      searchkwd.saveKwd(val);
      // window.dataLayer = window.dataLayer || [];
      setDataLayer(window.dataLayer || []);
      window.dataLayer.push({
        event: 'Data Layer Push Event',
        eventData: {
          category:
            typeof window.CLOC_PAGE_INFO !== 'undefined' && typeof window.CLOC_PAGE_INFO.ctg !== 'undefined'
              ? window.CLOC_PAGE_INFO.ctg
              : '페이지',
          action: 'click:search_검색창',
          label: '검색 실행',
        },
      });
      location.href = window.SEARCH_PATH + encodeURIComponent(val);
    } else {
      window.messageUtil.toast({ message: '검색어를 입력해주세요' });
    }
  });

  let enterKeydown1 = 0; // 중복 발생 방지하기 위한 변수
  // 출처: https://iseunghan.tistory.com/301 [iseunghan]
  // 검색 아이콘 눌렀을 때, enter 눌렀을 때 trigger submit
  $(searchkwd.selector.layer)
    .on('click', '.btn_search', function (e) {
      e.preventDefault();
      $(searchkwd.selector.form).trigger('submit');
    })
    .on('keydown', '.form_control', function (e) {
      if (e.originalEvent.key === 'Enter') {
        e.preventDefault();
      }
    })
    .on('keyup', '.form_control', function (e) {
      // console.log("이벤트 발생 = keyCode : " + e.keyCode + ", isComposing : " + event.isComposing);
      if (e.keyCode === 13) {
        e.preventDefault();
        e.stopPropagation();
        if (enterKeydown1 !== e.keyCode) {
          // console.log("엔터 실행");
          $(searchkwd.selector.form).trigger('submit');
        }
      } else {
        changeAutocomplateLayer({ $element: $(this), searchkwd });
      }
      enterKeydown1 = e.keyCode;
    });

  // 입력내용 삭제버튼
  $(searchkwd.selector.layer + ', .contents .search_form')
    .find('.input_group .btn_delete')
    .click(function (e) {
      $(e.currentTarget).siblings('.form_control').onlyTextNode().remove();
      // 레이어 닫고 다시 열었을때 힌트영역은 다시 보여야함 2023/03/23 주석처리
      // $('#layer_search .input_hint').addClass('hide');
      $(this).addClass('hide');

      changeAutocomplateLayer({ $element: $(e.currentTarget).siblings('.form_control'), searchkwd });
    });
  $(searchkwd.selector.layer).find('.search_area .ico_clear').css({ 'margin-left': 7 });

  // 입력된 내용이 있으면 텍스트 삭제 버튼 노출
  $(searchkwd.selector.control).keyup(function () {
    inputdelete();
  });

  $(searchkwd.selector.control).bind('paste', function () {
    inputdelete();
    changeAutocomplateLayer({ $element: $(this), searchkwd });
  });

  /**
   * 검색 결과 페이지
   */
  if (location.pathname.indexOf('/search') === 0) {
    changePositionSearchSticky();

    $('.btn_full').click(function () {
      const $a = $(this).find('a');
      if ($a.length > 0) {
        location.href = $a[0].href;
      }
    });

    // 전체/영상/포토
    $('#news_result .nav_link').click(function (e) {
      e.preventDefault();
      e.stopPropagation();
      $('#news_result .nav_link').removeClass('active');
      $(this).addClass('active');
      $('.chain_wrap .tab_pane').removeClass('active');
      for (let i = 1; i <= $('#news_result .nav_link').length; i++) {
        if ($(this).attr('id') === 'tabpanel' + i) {
          $('.chain_wrap #tab' + i).addClass('active');
        }
      }
    });

    // 첫번째 섹션의 header에 .bdr_none 추가
    const $firstHeader = $('.contents > .row > section.chain_wrap .header:first-child');
    if ($firstHeader.length > 0) {
      $firstHeader.addClass('bdr_none');
    }

    // 상단 input 검색
    const qo = window.utils.queryStringToObject(location.search);
    $('#search_page_top')
      .parents('.search_form')
      .submit(function (e) {
        e.preventDefault();

        let val = $(e.currentTarget).find('.form_control').onlyText();
        val = searchkwd.filterKwd(val, $(e.currentTarget).find('.form_control')[0]);

        if (!val && val.length < 1) {
          window.messageUtil.toast({ message: '검색어를 입력해주세요' });
        } else {
          e.stopPropagation();
          searchkwd.saveKwd(val);
          $.extend(qo, { keyword: val });
          // window.dataLayer = window.dataLayer || [];
          setDataLayer(window.dataLayer || []);
          window.dataLayer.push({
            event: 'Data Layer Push Event',
            eventData: {
              category:
                typeof window.CLOC_PAGE_INFO !== 'undefined' && typeof window.CLOC_PAGE_INFO.ctg !== 'undefined'
                  ? window.CLOC_PAGE_INFO.ctg
                  : '페이지',
              action: 'click:search_검색창',
              label: '검색 실행',
            },
          });
          location.href = location.origin + location.pathname + window.utils.objectToQueryString(qo);
        }
      });

    // 상세검색 팝업
    const $detail_layer = $('#layer_search_option');

    // 날짜 (라디오)
    unified.onChangeDate();

    // 체크박스
    unified.onChangeCheckbox();

    $(unified.form)
      .find('.form_option button')
      .click(function () {
        $(this).parents('.form_option').toggleClass('open');
      });

    // 폼의 input 변경 시 query update (이벤트 순서 지킬 것)
    $(unified.form)
      .find('.form_control')
      .keyup(function (e) {
        // 초기화, 확인 버튼 활성화
        $('#layer_search_option .layer_footer button').each(function () {
          this.removeAttribute('disabled');
        });

        if (e.keyCode === 13) {
          e.preventDefault();
          $('#search_detail').trigger('click');
        }
      });

    $(unified.form)
      .find('input')
      .on('change', function () {
        // 초기화, 확인 버튼 활성화
        $('#layer_search_option .layer_footer button').each(function () {
          this.removeAttribute('disabled');
        });

        // query 수정
        if (this.type === 'checkbox') {
          const selector = '[name="' + this.name + '"]:checked';
          const val = [];
          $(selector).each(function () {
            val.push(this.value);
          });

          const no = {};
          no[this.name] = val.join(',');
          $.extend(unified.query, no);

          const selectorname = '[name="' + this.name + '"]';
          const checked = $(selector).length;
          if (checked === 0) {
            $(selectorname + '[value="all"]')[0].checked = true;
          }
        } else {
          const no = {};
          no[this.name] = this.value;
          $.extend(unified.query, no);
        }
      });

    // query object -> 상세검색 팝업에 셋팅
    if ($detail_layer.length > 0) {
      unified.set(qo);
    }

    // 상세검색 팝업 컨트롤
    $detail_layer.find('.btn_close').click(function (e) {
      // common의 closeLayer 이벤트 제거하고 직접 제어
      e.stopPropagation();
      window.closeLayer(this);
      window.scrollStart();
    });

    // 자동완성 dom 생성
    const $autocompleteUl = $('<div class="search_input_list"></div>');
    $autocompleteUl.append('<ul class="list"></ul>');
    $('.search_header_wrap').append($autocompleteUl);

    // 입력 영역 클릭 -> p 트리거 focus
    $('.search_header_wrap .search_area').click(function (e) {
      e.preventDefault();
      e.stopPropagation();

      if (e.relatedTarget) return;

      $(this).parents('.search_form').addClass('focused');
      const $hint = $(this).parents('.search_form').find('.input_hint');
      if (!$hint.hasClass('hide')) {
        $(this).parents('.search_form').find('.input_hint').hideEle();
      }

      $(this).find('.form_control').trigger('focus');
      const val = $(this).find('.form_control').onlyText();
      searchkwd.getAutoComplete(val, $autocompleteUl.find('ul'));
      $(this).parents('.search_header_wrap').children().last().addClass('open');
    });

    let preKeyCode2 = 0; // 중복 발생 방지하기 위한 변수
    // 입력된 내용이 있으면 텍스트 삭제 버튼 노출
    $('.search_header_wrap .search_form .form_control')
      .keydown(function (e) {
        // console.log("이벤트 발생 = keyCode : " + e.keyCode + ", isComposing : " + event.isComposing);
        if (e.keyCode === 13) {
          e.preventDefault();
          e.stopPropagation();
          if (preKeyCode2 !== e.keyCode) {
            // console.log("엔터 실행");
            $('.search_header_wrap .btn_search').trigger('submit');
          }
        }
        preKeyCode2 = e.keyCode;
      })
      .keyup(function (e) {
        if (e.keyCode !== 13) {
          inputdelete('.search_header_wrap');
          const val = $(e.target).onlyText();
          searchkwd.getAutoComplete(val, $autocompleteUl.find('ul'));
        }
      })
      .focusout(function (e) {
        e.preventDefault();
        e.stopPropagation();

        if (e.relatedTarget && $(e.relatedTarget).parents('.search_input_list').length > 0) {
          // eslint-disable-next-line no-useless-return
          return;
        } else if (e.relatedTarget && $(e.relatedTarget).parents('.search_form').length > 0) {
          if ($(e.relatedTarget).is('button.btn_delete')) {
            $(this).parents('.search_form').removeClass('focused');
            $(this).parents('.search_header_wrap').children().last().removeClass('open');
          } else {
            // eslint-disable-next-line no-useless-return
            return;
          }
        } else if (e.relatedTarget && $(e.relatedTarget).parents('#layer_search_option').length > 0) {
          // eslint-disable-next-line no-useless-return
          return;
        } else {
          $(this).parents('.search_form').removeClass('focused');
          $(this).parents('.search_header_wrap').children().last().removeClass('open');
        }
      });

    // 상세검색 초기화
    $('#reset_detail').click(function () {
      unified.reset();
    });

    // 상세검색 확인 => 상세검색 페이지로 이동
    $('#search_detail').click(function () {
      // query 데이터 변경...
      const keyword = $('.search_header_wrap .form_control').onlyText();
      const q = $.extend({}, unified.query, { keyword });
      const now = new Date();

      if (q.searchDate === 'direct') {
        if (!q.startDate || q.startDate.length < 1) {
          q.startDate = now.format('yyyy-MM-dd');
        } else {
          q.startDate = q.startDate.toDate().format('yyyy-MM-dd');
        }
        if (!q.endDate || q.endDate.length < 1) {
          q.endDate = now.format('yyyy-MM-dd');
        } else {
          q.endDate = q.endDate.toDate().format('yyyy-MM-dd');
        }
        delete q.searchDate;
      } else if (q.searchDate === '1day') {
        q.searchDate = '1day';
        q.endDate = now.format('yyyy-MM-dd');
        q.startDate = new Date(now.getTime() - 1000 * 60 * 60 * 24).format('yyyy-MM-dd');
      } else if (q.searchDate === '1week') {
        q.searchDate = '1week';
        q.endDate = now.format('yyyy-MM-dd');
        q.startDate = new Date(now.getTime() - 1000 * 60 * 60 * 24 * 7).format('yyyy-MM-dd');
      } else if (q.searchDate === '1month') {
        q.searchDate = '1month';
        q.endDate = now.format('yyyy-MM-dd');
        q.startDate = new Date(now.setMonth(now.getMonth() - 1)).format('yyyy-MM-dd');
      } else if (q.searchDate === 'all') {
        q.endDate = '';
        q.startDate = '';
        delete q.searchDate;
      }

      if (q.serviceCode === 'all') {
        q.serviceCode = null;
      }
      if (q.sourceCode === 'all') {
        q.sourceCode = null;
      }

      // 원래 쿼리
      $.extend(qo, q);
      if (!q.searchDate) delete qo.searchDate;

      // 값이 null 혹은 undefined 인거 제거
      Object.keys(qo).forEach(function (key) {
        if (qo[key] === null || qo[key] === undefined) {
          delete qo[key];
        }
      });

      location.href = window.WEB_PATH + '/search/unifiedsearch' + window.utils.objectToQueryString(qo);
    });

    // 상세검색 팝업 오픈 시 고급 검색 자동 open
    $('.search_header_wrap .btn_option').click(function () {
      $detail_layer.find('.form_option').last().removeClass('hide');

      if (
        $(this).hasClass('active') &&
        // eslint-disable-next-line eqeqeq
        ((!window.utils.empty(unified.query.accurateWord) && unified.query.accurateWord != unified.init.accurateWord) ||
          // eslint-disable-next-line eqeqeq
          (!window.utils.empty(unified.query.searchin) && unified.query.searchin != unified.init.searchin) ||
          // eslint-disable-next-line eqeqeq
          (!window.utils.empty(unified.query.stopword) && unified.query.stopword != unified.init.stopword))
      ) {
        $detail_layer.find('.form_option').last().addClass('open');
      } else {
        $detail_layer.find('.form_option').last().removeClass('open');
      }
    });

    inputdelete('.search_header_wrap');
  }

  // 검색 결과 수 표기
  if ($('.btn_full').length > 0) {
    $('.btn_full').each(function () {
      const searchcount = parseInt($(this).find('span').text()).toLocaleString();
      $(this).find('span').text(searchcount);
    });
  }

  if ($('#searchcount').length > 0) {
    const searchcount = parseInt($('#searchcount').text()).toLocaleString();
    $('#searchcount').text(searchcount);
  }
}

/**
 * 검색 페이지 sticky 위치 조정
 * 기본이 fixed로 되어 있어서 조금 다릅니다
 */
function changePositionSearchSticky() {
  const $searchSticky = $('#searchSticky');
  if ($searchSticky.length < 1) return;

  const $stickyWrap = $('.title_wrap.bdr_none.bdr_bottom');
  const $wrap = $stickyWrap.parent();
  const $header = $('#header');

  const change = function () {
    const pageType = window.utils.device.getPageType();

    // this.searchOld = this.searchOld || 0;
    setSearchOld(window.searchOld || 0);

    // 스크롤 업다운 처리 (모바일에서만 스크롤을 위로 올릴 때 header 노출)
    if (pageType === 'mobile' || pageType === 'tablet') {
      const plusBannerHeight = $('#plus_top_banner').length > 0 ? 70 : 0;

      if (window.utils.getScrollY() === 0) {
        $searchSticky.css('top', plusBannerHeight + 60 + 'px');
      } else if (window.searchOld < window.utils.getScrollY()) {
        $header.addClass('position_ab');
        $searchSticky.css(
          'top',
          window.utils.getScrollY() < plusBannerHeight + 60
            ? plusBannerHeight + 60 - window.utils.getScrollY()
            : $header.hasClass('position_ab')
            ? 0
            : plusBannerHeight + 60
        );
        //          console.log('position log',  $searchSticky.css('top'), utils.getScrollY(), utils.getScrollY() < (plusBannerHeight + 60), $('#header').hasClass('position_ab'), $header.hasClass('position_ab'));
      } else if (window.searchOld >= window.utils.getScrollY()) {
        // + STICKY_DELAY) {
        $header.removeClass('position_ab');
        $searchSticky.css(
          'top',
          window.utils.getScrollY() < plusBannerHeight + 60
            ? plusBannerHeight + 60 - window.utils.getScrollY()
            : $searchSticky.hasClass('sticky_top')
            ? 0
            : 0
        );
      }

      if ($('#plus_top_banner').length > 0 && window.setStickyHeader) {
        window.setStickyHeader();
      }
    }

    const rect = $wrap[0].getBoundingClientRect();
    if (window.utils.getScrollY() === 0 || (rect.top >= 0 && $searchSticky.hasClass('sticky_top'))) {
      $wrap.removeAttr('style');
      $searchSticky.removeClass('sticky_top');
    } else if (rect.top < 0 && !$searchSticky.hasClass('sticky_top')) {
      $wrap.css('padding-top', pageType === 'pc' || pageType === 'wide' ? $stickyWrap.outerHeight() : 0);
      $searchSticky.addClass('sticky_top');
    }

    setSearchOld(window.utils.getScrollY());
  };

  change();
  window.addEventListener('scroll', change);
  window.addEventListener('resize', change);
}

/**
 * 내부영역 클릭해도 검색리스트 닫지않도록
 * @param {*} parents
 */
function searcharea(parents, event) {
  let issearchareaclick = 0;
  let searchnotarea = 0;
  const $target = $(event.target);
  parents.each(function () {
    const parentclassname = $(this).attr('class');
    if (parentclassname === 'layer_body' || (parentclassname && parentclassname.indexOf('search_header_wrap') > -1)) {
      issearchareaclick += 1;
    }
    if (parentclassname === 'search_tag_wrap' || parentclassname === 'chain_wrap') {
      searchnotarea = 1;
    }

    if ($target.hasClass('btn_delete') || $target.hasClass('ico_close')) {
      issearchareaclick = 0;
    }
  });
  if (issearchareaclick > 0 && searchnotarea === 0) {
    $(event.currentTarget).find('.form_control').addClass('focused');
    $(event.currentTarget).find('.form_control').focus();
    focusEvent();
  } else {
    $(event.currentTarget).find('.form_control').removeClass('focused');
    focusEvent();
  }
}

// 이전 focus 상태 저장
// window.focused = false;
setFocused(false);

/**
 * focus에 따라 search_input_list 가져오거나 숨김
 */
function focusEvent() {
  const cstatus = $('#layer_search .form_control').hasClass('focused');

  if (cstatus && !window.focused) {
    // window.focused = true;
    setFocused(true);

    $('.search_input_list').addClass('open');
    $('.search_input_list').showEle();
    $('#layer_search .chain_wrap').hideEle();
    $('#layer_search .input_hint').hideEle();
    $('#layer_search .house_banner').hideEle();
    $('#layer_search .search_tag_wrap').hideEle();
    $('#layer_search .input_hint').text('');
    // Safari - 검색어 입력 영역 클릭 시, 입력 영역에 포커스 주기
    // $('#layer_search .search_area .form_control').text('');
    $('#layer_search .search_area .form_control').blur();
    $('#layer_search .search_area .form_control').focus();
  } else if (!cstatus && window.focused) {
    const formText = $('#layer_search .form_control').text();

    // 입력한 값이 없을 때만 닫기
    if (formText.length < 1) {
      if ($('#layer_search .input_hint').length === 0) {
        $('#layer_search .form_control').append('<span class="input_hint" contenteditable="false"></span>');
      }
      $('#layer_search .input_hint').showEle();
      $('#layer_search .input_hint').text('검색어를 입력해주세요');
      $('#layer_search .form_control').css('width', '');
      $('.search_input_list').removeClass('open');
      $('#layer_search .chain_wrap').showEle();
      $('#layer_search .house_banner').showEle();
      $('#layer_search .search_tag_wrap').showEle();
      // window.focused = false;
      setFocused(false);
    }
  }
}

/**
 * 입력된 내용 삭제버튼
 */
function inputdelete(selector) {
  selector = window.utils.defaultValue(selector, '#layer_search');
  const text = $(selector + ' .form_control').onlyText();
  const $hint = $(selector + ' .input_hint');
  const $del = $(selector + ' .search_area .btn_delete');

  if (window.utils.device.getPageType() === 'mobile' || window.utils.device.getPageType() === 'tablet') {
    if (!$hint.length || $hint.hasClass('hide') === true) {
      if (text.length < 1) {
        $del.addClass('hide');
      } else {
        $del.removeClass('hide');
      }
    } else {
      $del.addClass('hide');
    }
  } else {
    $del.addClass('hide');
  }
}

function changeAutocomplateLayer({ $element, searchkwd }) {
  const parsed = $.trim($element.text());
  if (parsed.length > 0) {
    // 내 자동완성 검색어 보는 영역 hide
    $(searchkwd.selector.savedKwdList).hideEle();
    $(searchkwd.selector.layer).find('.search_off').hideEle();
  } else if (!searchkwd.savemode || searchkwd.my.length < 1) {
    $(searchkwd.selector.layer).find('.search_off').showEle();
    $(searchkwd.selector.savedKwdList).hideEle();
  } else {
    $(searchkwd.selector.savedKwdList).showEle();
  }

  // 자동완성 연결
  searchkwd.getAutoComplete(parsed, searchkwd.selector.autocomplete);
  // 추천 검색어 연결
  searchkwd.getRecommend(parsed);
}
